import { createLucideIcon } from '@qasa/qds-ui'
import { Palmtree, FileCheck2, LayoutList, UserSquare2, PlusCircle, Headset } from 'lucide-react'

export const ContractIcon = createLucideIcon(FileCheck2)

export const UserSquareIcon = createLucideIcon(UserSquare2)

export const CreateListingIcon = createLucideIcon(PlusCircle)

export const MyListingsIcon = createLucideIcon(LayoutList)

export const TreePalmIcon = createLucideIcon(Palmtree)

export const HeadsetIcon = createLucideIcon(Headset)
