import styled from '@emotion/styled'

import { NavigationalLogo } from './navigational-logo'
import type { NavigationalLogoProps } from './navigational-logo'

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  marginRight: theme.spacing['4x'],
}))

export function LogoSection({ colorVariant }: NavigationalLogoProps) {
  return (
    <Wrapper>
      <NavigationalLogo colorVariant={colorVariant} />
    </Wrapper>
  )
}
