import { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button, Stack, useBreakpointValue } from '@qasa/qds-ui'

import { LinkButtonV2 } from '../../ui-shared/_core/link-button-v2'
import { useAuthContext } from '../../context/auth-context'
import { currentBrand } from '../../config'
import barImg from '../../assets/misc/blocket/blocket-colorbar-header.svg'
import { useInitiateLogin } from '../../hooks/use-initiate-login'
import { getPath } from '../../routing/get-path'

import { LogoSection } from './components/logo-section'
import { NavLink } from './components/nav-link'
import { NavAvatar } from './components/nav-avatar'
import { NavMessagesIcon } from './components/nav-messages-icon'
import { useNavBarItems } from './components/use-nav-bar-items'
import { HamburgerMenuButton } from './components/hamburger-menu-button'
import { NavFavoritesIcon } from './components/nav-favorites-icon'
import { NavMenuMobile } from './components/nav-menu-mobile'
import { NavMenuMobileContent } from './components/nav-menu-mobile-content'
import { useNavMenuItems } from './components/use-nav-menu-items'
import { NAV_HEIGHT } from './constants'
import { NavMenuDesktop } from './components/nav-menu-desktop'
import { NavMenuDesktopContent } from './components/nav-menu-desktop-content'

const BLOCKET_COLOR_BAR_HEIGHT = 8

const Wrapper = styled.div<{ textColorOverride?: string; isScrolled: boolean }>(
  ({ theme, textColorOverride, isScrolled }) => ({
    position: 'sticky',
    right: 0,
    left: 0,
    top: 0,
    height: NAV_HEIGHT,
    zIndex: theme.zIndices.docked,
    color: textColorOverride || 'inherit',
    boxShadow: isScrolled ? theme.shadows.sm : 'none',
    transition: 'box-shadow 300ms ease-in-out',
  }),
)
const InnerWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  height: currentBrand === 'blocket' ? `calc(100% - ${BLOCKET_COLOR_BAR_HEIGHT}px)` : '100%',
  padding: `0 ${theme.spacing['6x']}`,
  display: 'flex',
  alignItems: 'center',
}))
const RightSideWrapper = styled(Stack)({ marginLeft: 'auto', position: 'relative' })
const BlocketColorBar = styled.div({
  height: BLOCKET_COLOR_BAR_HEIGHT,
  marginTop: -1, //To compensate for shape of SVG causing a 1px gap
  width: '100%',
  backgroundImage: `url(${barImg.src})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'auto',
})

type TopNavBarProps = { className?: string; textColorOverride?: string }

export function TopNavBar({ className, textColorOverride }: TopNavBarProps) {
  const { isAuthenticated, authBody } = useAuthContext()
  const isLandlord = authBody?.landlord
  const isTenant = authBody?.tenant
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const closeNavMenu = useCallback(() => setIsNavMenuOpen(false), [])
  const navBarItems = useNavBarItems()
  const menuItems = useNavMenuItems()
  const { t } = useTranslation('top_nav_bar')
  const { initiateLogin } = useInitiateLogin()
  const isQasaFrance = currentBrand === 'qasa_france'

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isSmOrAbove = useBreakpointValue({ base: false, sm: true }, { ssr: true })
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: true })

  const shouldTextColorOverride = textColorOverride && (isMdOrAbove || !isNavMenuOpen)

  const shouldShowFavoritesIcon = isTenant && !isQasaFrance
  const shouldShowSignInButton = !isAuthenticated && isSmOrAbove

  const shouldShowCreateAdButton = (!isAuthenticated || isLandlord) && !isQasaFrance

  const shouldShowNavMessages = isAuthenticated && !isQasaFrance
  // NOTE: In FR we always show the profile button if the user is authenticated
  const shouldShowProfileButton = isAuthenticated && (isQasaFrance || isMdOrAbove)

  return (
    <Wrapper
      textColorOverride={shouldTextColorOverride ? textColorOverride : undefined}
      isScrolled={isScrolled}
    >
      {currentBrand === 'blocket' && <BlocketColorBar />}
      <InnerWrapper direction="row" {...{ className }}>
        <LogoSection colorVariant={shouldTextColorOverride ? 'light' : 'default'} />
        {navBarItems.map(({ label_i18n, ...linkProps }) => (
          <NavLink key={label_i18n} {...linkProps} textColorOverride={textColorOverride}>
            {t(`links.${label_i18n}`)}
          </NavLink>
        ))}
        <RightSideWrapper gap="2x" direction="row" alignItems="center">
          {shouldShowSignInButton && (
            <Button variant="tertiary" onClick={initiateLogin}>
              {t('login')}
            </Button>
          )}
          {shouldShowCreateAdButton && (
            <LinkButtonV2 size={isSmOrAbove ? 'md' : 'sm'} variant="tertiary" href={getPath('createListing')}>
              {t('links.create_ad')}
            </LinkButtonV2>
          )}

          {(shouldShowNavMessages || shouldShowFavoritesIcon || shouldShowProfileButton) && (
            <Stack direction={'row'} alignItems="center" gap="2x">
              {shouldShowNavMessages && <NavMessagesIcon />}
              {shouldShowFavoritesIcon && <NavFavoritesIcon />}
              {shouldShowProfileButton && <NavAvatar />}
            </Stack>
          )}
          {isMdOrAbove ? (
            <NavMenuDesktop isOpen={isNavMenuOpen} onOpenChange={setIsNavMenuOpen}>
              <NavMenuDesktop.Trigger asChild>
                <HamburgerMenuButton isActive={isNavMenuOpen} />
              </NavMenuDesktop.Trigger>
              <NavMenuDesktop.Content>
                <NavMenuDesktopContent items={menuItems} onClose={closeNavMenu} />
              </NavMenuDesktop.Content>
            </NavMenuDesktop>
          ) : (
            <>
              <HamburgerMenuButton
                isActive={isNavMenuOpen}
                onClick={() => setIsNavMenuOpen((isOpen) => !isOpen)}
              />
              <NavMenuMobile isOpen={isNavMenuOpen} onClose={closeNavMenu}>
                <NavMenuMobileContent items={menuItems} onClose={closeNavMenu} />
              </NavMenuMobile>
            </>
          )}
        </RightSideWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}
