import { GlobeIcon, LogOutIcon, Stack, UserIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useInitiateLogin } from '../../../hooks/use-initiate-login'
import { AVAILABLE_LOCALES } from '../../../translations/language-loader/language-utils'
import { useAuthContext } from '../../../context/auth-context'
import { useCurrentLocale } from '../../../translations/use-current-locale'

import type { NavMenuItemType } from './use-nav-menu-items'
import { LanguageSwitchDialog } from './language-switch-dialog'
import { NavMenuDesktop } from './nav-menu-desktop'

type NavMenuMobileContentProps = {
  items: NavMenuItemType[]
  onClose: () => void
}
export function NavMenuDesktopContent({ items, onClose }: NavMenuMobileContentProps) {
  const { t } = useTranslation('nav_menu')
  const { initiateLogin } = useInitiateLogin()
  const { logout, isAuthenticated } = useAuthContext()
  const locale = useCurrentLocale()
  const currentLanguage = AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)

  return (
    <Stack as="ul">
      {items.map((item, index) => {
        if ('divider' in item) {
          return <NavMenuDesktop.Divider key={index} />
        }
        if ('languageSelector' in item) {
          return (
            <li key={index}>
              <LanguageSwitchDialog
                trigger={
                  <NavMenuDesktop.Button
                    icon={GlobeIcon}
                    // Should never be undefined, but adding a fallback just in case
                    label={currentLanguage?.nativeName || 'Choose language'}
                  />
                }
              />
            </li>
          )
        }

        if ('loginButton' in item) {
          return (
            <li key={index}>
              <NavMenuDesktop.Button
                icon={UserIcon}
                label={t(`links.login`)}
                onClick={() => {
                  initiateLogin()
                  onClose()
                }}
              />
            </li>
          )
        }
        const { label_i18n, icon, href } = item

        return (
          <li key={href}>
            <NavMenuDesktop.Link href={href} icon={icon} label={t(`links.${label_i18n}`)} />
          </li>
        )
      })}
      {isAuthenticated && (
        <>
          <NavMenuDesktop.Divider />
          <NavMenuDesktop.Button
            icon={LogOutIcon}
            label={t('logout')}
            onClick={() => {
              logout()
              onClose()
            }}
          />
        </>
      )}
    </Stack>
  )
}
