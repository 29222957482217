import { useTranslation } from 'react-i18next'
import { HeartIcon } from '@qasa/qds-ui'

import { getPath } from '../../../routing/get-path'

import { NavIconWithBadge } from './nav-icon-with-badge'
import { IconLink } from './nav-messages-icon'

export function NavFavoritesIcon() {
  const { t } = useTranslation('top_nav_bar')

  return (
    <IconLink aria-label={t('icon_aria_labels.favorites')} href={getPath('favorites')}>
      <NavIconWithBadge badgeCount={0}>
        <HeartIcon size={20} color="currentColor" />
      </NavIconWithBadge>
    </IconLink>
  )
}
