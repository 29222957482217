import type { IconProps } from '@qasa/qds-ui'
import { HelpCircleIcon, SettingsIcon } from '@qasa/qds-ui'
import type { ElementType } from 'react'

import { useAuthContext } from '../../../context/auth-context'
import { getPath } from '../../../routing/get-path'
import { ContractIcon } from '../icons'

import { useNavBreakpoint } from './use-nav-breakpoint'

const NAV_MENU_ITEMS = {
  SETTINGS: { label_i18n: 'settings', href: getPath('settings'), icon: SettingsIcon },
  HELP: {
    label_i18n: 'help',
    href: getPath('help'),
    icon: HelpCircleIcon,
  },
  LEASES: { label_i18n: 'leases', href: getPath('leases'), icon: ContractIcon },
} as const

type NavMenuItemType =
  | { label_i18n: string; icon: ElementType<IconProps>; href: string }
  | { divider: boolean }
  | { languageSelector: boolean }
  | { loginButton: boolean }

export const useNavMenuItemsFrance = (): NavMenuItemType[] => {
  const { isAuthenticated } = useAuthContext()
  const { isCollapsed } = useNavBreakpoint()

  if (isAuthenticated) {
    return [
      ...(isCollapsed ? [NAV_MENU_ITEMS.LEASES] : []),

      NAV_MENU_ITEMS.SETTINGS,
      { divider: true },
      NAV_MENU_ITEMS.HELP,
      { languageSelector: true },
    ]
  }

  return [
    ...(isCollapsed ? [NAV_MENU_ITEMS.LEASES] : []),
    NAV_MENU_ITEMS.HELP,
    { languageSelector: true },
    { divider: true },
    { loginButton: true },
  ]
}
