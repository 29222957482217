import styled from '@emotion/styled'
import type { ReactNode } from 'react'

import { NotificationBadge } from '../../../ui-shared/_core/notification-badge'

const IconContainer = styled.div({ position: 'relative', display: 'flex' })
const BadgeContainer = styled.div({
  position: 'absolute',
  right: -12,
  top: -8,
})
export function NavIconWithBadge({
  badgeCount,
  children,
  className = '',
}: {
  badgeCount: number
  children: ReactNode
  className?: string
}) {
  return (
    <IconContainer className={className}>
      {badgeCount ? (
        <BadgeContainer>
          <NotificationBadge text={badgeCount} height={18} />
        </BadgeContainer>
      ) : null}
      {children}
    </IconContainer>
  )
}
