import { useSearchParams } from 'next/navigation'

import { useAuthenticatedAction } from '../helpers/auth-callback'
import { useAuthDialogContext } from '../context/auth-dialog-context'
import { BRAND_TOGGLES } from '../config/toggles'
import { AFTER_LOGIN_ACTIONS } from '../helpers/auth-callback.types'
import { usePathname } from '../vendor/next'

export const useInitiateLogin = () => {
  const { pushToLoginWithAfterAction } = useAuthenticatedAction()
  const pathname = usePathname()
  const search = useSearchParams().toString()
  const { setIsAuthDialogOpen } = useAuthDialogContext()
  const { availableLoginMethods } = BRAND_TOGGLES

  const hasSingleSchibstedLoginMethod =
    availableLoginMethods.length === 1 && ['schibsted_fi', 'schibsted_se'].includes(availableLoginMethods[0])

  const initiateLogin = () => {
    if (hasSingleSchibstedLoginMethod) {
      pushToLoginWithAfterAction({
        type: AFTER_LOGIN_ACTIONS.GO_TO_ROUTE,
        route: pathname,
        search,
      })
    } else {
      setIsAuthDialogOpen(true)
    }
  }

  return { initiateLogin }
}
