import { useQuery } from '@apollo/client'
import { graphql } from '@qasa/graphql'
import { useEffect } from 'react'

import { useCurrentUser } from '../contexts/current-user'
import { getEnv } from '../env'
import { useToggles } from '../configs/use-toggles'

import { usePageVisibility } from './use-page-visibility'

const NOTIFICATIONS_POLLING_INTERVAL = parseInt(getEnv('CHAT_LIST_POLLING_INTERVAL') ?? '', 10) || 30_000

const USER_UNREAD_CONVERSATIONS_COUNT = graphql(`
  query UserUnreadConversationsCount {
    me {
      uid
      private {
        id
        unreadConversationsCount
      }
    }
  }
`)

export const useUnreadConversationsCount = () => {
  const { isAuthenticated } = useCurrentUser()
  const { isPageVisible } = usePageVisibility()
  const { notificationsEnabled: isNotificationsEnabled } = useToggles()
  const { startPolling, stopPolling, data } = useQuery(USER_UNREAD_CONVERSATIONS_COUNT, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  })

  useEffect(() => {
    if (isAuthenticated && isNotificationsEnabled) {
      if (isPageVisible) {
        startPolling(NOTIFICATIONS_POLLING_INTERVAL)
      } else {
        stopPolling()
      }
    }

    return () => {
      stopPolling()
    }
  }, [data, isAuthenticated, isNotificationsEnabled, isPageVisible, startPolling, stopPolling])

  const unreadConversationsCount = data?.me?.private.unreadConversationsCount ?? 0

  return { unreadConversationsCount }
}
