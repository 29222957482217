import styled from '@emotion/styled'
import type { ElementType, ReactNode } from 'react'
import { useEffect, forwardRef } from 'react'
import type { IconProps } from '@qasa/qds-ui'
import { Divider, pxToRem } from '@qasa/qds-ui'
import * as PopoverPrimitive from '@radix-ui/react-popover'

import { usePathname, Link } from '../../../vendor/next'

const StyledContent = styled(PopoverPrimitive.Content)(({ theme }) => ({
  background: theme.colors.bg.default,
  borderRadius: theme.radii.lg,
  border: `1px solid ${theme.colors.border.subtle}`,
  zIndex: theme.zIndices.dropdown,
  minWidth: theme.sizes['288'],
  maxWidth: theme.sizes['320'],
  boxShadow: theme.shadows.lg,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing['3x'],
}))

function NavMenuContent({ children }: { children: ReactNode }) {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent align="end" sideOffset={8}>
        {children}
      </StyledContent>
    </PopoverPrimitive.Portal>
  )
}

const NavButton = styled.button(({ theme }) => ({
  width: '100%',
  ...theme.typography.label.md,
  color: theme.colors.text.default,
  display: 'flex',
  alignItems: 'center',
  height: pxToRem(48),
  paddingInline: theme.spacing['3x'],
  borderRadius: theme.radii.sm,
  flexShrink: 0,
  ':hover': {
    backgroundColor: theme.colors.bg.inset,
  },
  ':active': {
    backgroundColor: theme.colors.core.gray20,
  },
  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',
}))

type NavMenuButtonProps = {
  onClick?: () => void
  icon: ElementType<IconProps>
  label: string
}

const NavMenuButton = forwardRef<HTMLButtonElement, NavMenuButtonProps>(
  ({ onClick, icon: Icon, label, ...restProps }, forwardedRef) => {
    return (
      <NavButton
        ref={forwardedRef}
        onClick={onClick}
        // Need to spread the props for Radix dialogs to work
        {...restProps}
      >
        <IconContainer>
          <Icon size={20} />
        </IconContainer>
        {label}
      </NavButton>
    )
  },
)

const NavLink = NavButton.withComponent(Link)

const IconContainer = styled.span(({ theme }) => ({
  flexShrink: 0,
  marginRight: theme.spacing['4x'],
  transform: 'translateY(-1px)',
}))

type NavMenuLinkProps = {
  href: string
  icon: ElementType<IconProps>
  label: string
}

function NavMenuLink({ href, icon: Icon, label }: NavMenuLinkProps) {
  return (
    <NavLink href={href}>
      <IconContainer>
        <Icon size={20} />
      </IconContainer>
      {label}
    </NavLink>
  )
}

const DividerContainer = styled.div(({ theme }) => ({
  marginInline: `calc(-1 * ${theme.spacing['3x']})`,
  marginBlock: theme.spacing['3x'],
}))

function NavDivider() {
  return (
    <DividerContainer>
      <Divider />
    </DividerContainer>
  )
}
type NavMenuRootProps = { children: ReactNode; isOpen: boolean; onOpenChange: (open: boolean) => void }
function NavMenuRoot({ children, isOpen, onOpenChange }: NavMenuRootProps) {
  const pathname = usePathname()

  // Close the menu when the pathname changes
  useEffect(() => {
    onOpenChange(false)
  }, [pathname, onOpenChange])

  return (
    <PopoverPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      {children}
    </PopoverPrimitive.Root>
  )
}

export const NavMenuDesktop = Object.assign(NavMenuRoot, {
  Trigger: PopoverPrimitive.Trigger,
  Content: NavMenuContent,
  Button: NavMenuButton,
  Link: NavMenuLink,
  Divider: NavDivider,
})
