import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import type { LinkProps } from '../../../vendor/next'
import { Link, usePathname } from '../../../vendor/next'

type StyledLinkProps = {
  textColorOverride?: string
  isActive: boolean
}
const StyledLink = styled(Link, { shouldForwardProp: isPropValid })<StyledLinkProps>(
  ({ theme, isActive, textColorOverride }) => ({
    ...theme.typography.body.md,
    color: textColorOverride ?? theme.colors.text.default,
    padding: `${theme.spacing['3x']} ${theme.spacing['4x']}`,
    borderRadius: theme.radii.full,
    ...(isActive && {
      fontWeight: theme.typography.label.md.fontWeight,
    }),
    ':hover': {
      background: theme.colors.core.gray20,
      color: theme.colors.text.default,
    },
  }),
)

type Props = LinkProps & { textColorOverride?: string }

export function NavLink({ href, textColorOverride, ...rest }: Props) {
  const pathname = usePathname()

  const isActive = typeof href === 'string' ? href === pathname : href.pathname === pathname

  return <StyledLink href={href} isActive={isActive} textColorOverride={textColorOverride} {...rest} />
}
