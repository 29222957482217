import styled from '@emotion/styled'

const Badge = styled.div<{ height: number }>(({ theme, height }) => ({
  ...theme.typography.caption.sm,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 6px',
  height,
  minWidth: height,
  color: theme.colors.text.onBrandPrimary,
  borderRadius: height / 2,
  backgroundColor: theme.colors.bg.brandPrimary,
}))

type NotificationBadgeProps = { text: number | string; height?: number; className?: string }

export function NotificationBadge({ className, text, height = 16 }: NotificationBadgeProps) {
  return (
    <Badge height={height} className={className}>
      {text}
    </Badge>
  )
}
