import styled from '@emotion/styled'
import { RemoveScroll } from 'react-remove-scroll'
import { createPortal } from 'react-dom'
import type { ReactNode, ElementType } from 'react'
import { forwardRef, useEffect } from 'react'
import type { IconProps } from '@qasa/qds-ui'
import { Divider, pxToRem } from '@qasa/qds-ui'

import { NAV_HEIGHT } from '../constants'
import { Link, usePathname } from '../../../vendor/next'

const Wrapper = styled.div(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100vw',
  paddingTop: NAV_HEIGHT,
  // Note: Using `vh` doesn't work well with Safari's bottom bar on mobile
  height: '100%',
  background: theme.colors.bg.default,
  overflowY: 'auto',
}))

const Content = styled.div(({ theme }) => ({
  paddingTop: theme.spacing['6x'],
  paddingBottom: theme.spacing['6x'],
}))

type NavMenuRootProps = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

// TODO: We'd preferably want to use `Dialog` from Radix here, but that means
// we need to find a way to make the header bar interactive (e.g. the logo).
// By default the `Dialog` makes other elements inert.
function NavMenuRoot({ children, isOpen, onClose }: NavMenuRootProps) {
  const pathname = usePathname()

  // Close the menu when the pathname changes
  useEffect(() => {
    onClose()
  }, [onClose, pathname])

  if (!isOpen) return null

  return createPortal(
    <RemoveScroll as={Wrapper} className={RemoveScroll.classNames.fullWidth}>
      <Content>{children}</Content>
    </RemoveScroll>,
    document.body,
  )
}

const NavButton = styled.button(({ theme }) => ({
  width: '100%',
  ...theme.typography.body.lg,
  // Doing a one-off here. We only have one menu like this so for now it's fine
  // not adding it to QDS.
  fontWeight: theme.typography.label.md.fontWeight,
  color: theme.colors.text.default,
  display: 'flex',
  alignItems: 'center',
  minHeight: pxToRem(64),
  paddingInline: theme.spacing['6x'],
  paddingBlock: theme.spacing['3x'],
  flexShrink: 0,
  ':active': {
    backgroundColor: theme.colors.bg.inset,
  },
  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',
}))
const NavLink = NavButton.withComponent(Link)

const IconContainer = styled.span(({ theme }) => ({
  flexShrink: 0,
  marginRight: theme.spacing['4x'],
  transform: 'translateY(-1px)',
}))

type NavMenuLinkProps = {
  href: string
  icon: ElementType<IconProps>
  label: string
}

function NavMenuLink({ href, icon: Icon, label }: NavMenuLinkProps) {
  return (
    <NavLink href={href}>
      <IconContainer>
        <Icon size={20} />
      </IconContainer>
      {label}
    </NavLink>
  )
}
type NavMenuButtonProps = {
  onClick?: () => void
  icon: ElementType<IconProps>
  label: string
}

const NavMenuButton = forwardRef<HTMLButtonElement, NavMenuButtonProps>(
  ({ onClick, icon: Icon, label, ...restProps }, forwardedRef) => {
    return (
      <NavButton
        ref={forwardedRef}
        onClick={onClick}
        // Need to spread the props for Radix dialogs to work
        {...restProps}
      >
        <IconContainer>
          <Icon size={20} />
        </IconContainer>
        {label}
      </NavButton>
    )
  },
)

const DividerContainer = styled.div(({ theme }) => ({
  marginBlock: theme.spacing['2x'],
}))

function NavDivider() {
  return (
    <DividerContainer>
      <Divider />
    </DividerContainer>
  )
}

export const NavMenuMobile = Object.assign(NavMenuRoot, {
  Link: NavMenuLink,
  Button: NavMenuButton,
  Divider: NavDivider,
})
