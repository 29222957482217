import type { IconProps } from '@qasa/qds-ui'
import {
  HelpCircleIcon,
  SearchIcon,
  SettingsIcon,
  MessageCircleIcon,
  UserIcon,
  HeartIcon,
} from '@qasa/qds-ui'
import type { ElementType } from 'react'

import { useAuthContext } from '../../../context/auth-context'
import { getPath } from '../../../routing/get-path'
import {
  ContractIcon,
  CreateListingIcon,
  UserSquareIcon,
  TreePalmIcon,
  MyListingsIcon,
  HeadsetIcon,
} from '../icons'
import { currentBrand } from '../../../config'
import { availableRentalTypes } from '../../../config/markets'

import { useNavBreakpoint } from './use-nav-breakpoint'
import { useNavMenuItemsFrance } from './use-nav-menu-items-france'

const NAV_MENU_ITEMS = {
  PROFILE: { label_i18n: 'profile', href: getPath('profile'), icon: UserIcon },
  SETTINGS: { label_i18n: 'settings', href: getPath('settings'), icon: SettingsIcon },
  HOW_IT_WORKS: { label_i18n: 'how_it_works', href: getPath('howItWorks'), icon: HelpCircleIcon },
  HELP: {
    label_i18n: 'help',
    href: getPath('help'),
    icon: HeadsetIcon,
  },
  HOMES: { label_i18n: 'homes', href: getPath('findHome'), icon: SearchIcon },
  VACATION_HOMES: {
    label_i18n: 'vacation_homes',
    href: getPath('findVacation'),
    icon: TreePalmIcon,
  },
  LISTINGS: { label_i18n: 'listings', href: getPath('myListings'), icon: MyListingsIcon },
  FIND_TENANT: {
    label_i18n: 'find_tenant',
    href: getPath('findTenant'),
    icon: UserSquareIcon,
  },
  LEASES: { label_i18n: 'leases', href: getPath('leases'), icon: ContractIcon },
  SUBLET: { label_i18n: 'sublet', href: getPath('createListing'), icon: CreateListingIcon },
  MESSAGES: { label_i18n: 'messages', href: getPath('messages'), icon: MessageCircleIcon },
  FAVORITES: { label_i18n: 'favorites', href: getPath('favorites'), icon: HeartIcon },
} as const

export type NavMenuItemType =
  | { label_i18n: string; icon: ElementType<IconProps>; href: string }
  | { divider: boolean }
  | { languageSelector: boolean }
  | { loginButton: boolean }

export function useNavMenuItems(): NavMenuItemType[] {
  const { isAuthenticated, authBody } = useAuthContext()
  const { isCollapsed } = useNavBreakpoint()
  const isTenant = authBody?.tenant

  const itemsFrance = useNavMenuItemsFrance()

  if (currentBrand === 'qasa_france') {
    return itemsFrance
  }

  const hasMultipleRentalTypes = availableRentalTypes.length > 1

  if (!isAuthenticated) {
    const defaultNavMenuItems: NavMenuItemType[] = [
      NAV_MENU_ITEMS.HELP,
      { languageSelector: true },
      { divider: true },
      { loginButton: true },
    ]
    if (!isCollapsed) {
      return defaultNavMenuItems
    }
    const mobileNavMenuItems: NavMenuItemType[] = [
      NAV_MENU_ITEMS.HOMES,
      NAV_MENU_ITEMS.FIND_TENANT,
      ...(hasMultipleRentalTypes ? [NAV_MENU_ITEMS.VACATION_HOMES] : []),
      { divider: true },
      NAV_MENU_ITEMS.SUBLET,
      { divider: true },
      NAV_MENU_ITEMS.HOW_IT_WORKS,
      ...defaultNavMenuItems,
      ...(isTenant ? [NAV_MENU_ITEMS.FAVORITES] : []),
    ]
    return mobileNavMenuItems
  }

  const navMenuItems: NavMenuItemType[] = [
    ...(isTenant
      ? [NAV_MENU_ITEMS.PROFILE, NAV_MENU_ITEMS.SETTINGS]
      : [NAV_MENU_ITEMS.LISTINGS, NAV_MENU_ITEMS.LEASES, NAV_MENU_ITEMS.FAVORITES]),
    { divider: true },
    ...(isTenant ? [NAV_MENU_ITEMS.LEASES] : [NAV_MENU_ITEMS.PROFILE, NAV_MENU_ITEMS.SETTINGS]),
    { divider: true },
    NAV_MENU_ITEMS.HELP,
    { languageSelector: true },
  ]

  if (!isCollapsed) {
    return navMenuItems
  }

  const mobileNavMenuItems: NavMenuItemType[] = [
    NAV_MENU_ITEMS.HOMES,
    NAV_MENU_ITEMS.FIND_TENANT,
    ...(hasMultipleRentalTypes ? [NAV_MENU_ITEMS.VACATION_HOMES] : []),
    { divider: true },
    ...navMenuItems,
    NAV_MENU_ITEMS.HOW_IT_WORKS,
  ]

  return mobileNavMenuItems
}
