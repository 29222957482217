import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { MessageCircleIcon } from '@qasa/qds-ui'
import { useUnreadConversationsCount } from '@qasa/app/src/hooks/use-unread-conversation-count'

import { getPath } from '../../../routing/get-path'
import { Link } from '../../../vendor/next'

import { NavIconWithBadge } from './nav-icon-with-badge'

export const IconLink = styled(Link)({
  color: 'inherit',
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export function NavMessagesIcon() {
  const { t } = useTranslation('top_nav_bar')
  const { unreadConversationsCount } = useUnreadConversationsCount()

  return (
    <IconLink aria-label={t('icon_aria_labels.messages')} href={getPath('messages')}>
      <NavIconWithBadge badgeCount={unreadConversationsCount}>
        <MessageCircleIcon size={20} color="currentColor" />
      </NavIconWithBadge>
    </IconLink>
  )
}
